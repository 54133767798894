
import { useRouter } from "vue-router";
import { Usable } from "@/assets/api/production/public";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import bus from "@/utils/bus";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
  computed,
} from "vue";

import { useStore } from "vuex";
import { List } from "echarts";
export default defineComponent({
  name: "",
  props: {
    list: {
      type: Array,
    },
    rightType: {
      type: Boolean,
      default: false,
    },
    echo: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      value: "",
      options: [],
    });

    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getList(props.list);
      data.value = props.echo;
    });

    watch(
      props,
      (newValue) => {
        getList(JSON.parse(JSON.stringify(newValue.list)));
      },
      { deep: true }
    );

    watch(
      () => props.echo,
      (newValue, oldValue) => {
        data.value = newValue;
      },
      { deep: true }
    );

    onUnmounted(() => {
      bus.off("getList");
    });

    const getChange = (e: any) => {
      context.emit("getChange", e);
    };

    const getList = (list: any) => {
      if (list.length == 0) {
        data.value = "";
      }
      data.options = list;
    };

    return {
      ...toRefs(data),
      getChange,
    };
  },
});
