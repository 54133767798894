<template>
  <div>
    <el-table :data="rightsDate" style="width: 100%" @row-click="row_click">
      <el-table-column
        show-overflow-tooltip
        v-for="(info, i) in rightHeader"
        :key="i"
        :property="info.value"
        :label="info.label"
        align="center"
      >
        <template #default="scope">
          {{ scope.row[scope.column.property] }}
          <!-- 渲染对应表格里面的内容 -->
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="tltleName"
        width="200"
        fixed="right"
        v-if="caozuo"
      >
        <template #default="scope">
          <div
            :class="[operateList.length == 1 ? 'ju-c' : 'ju-s']"
            class="row flex ju-s box"
          >
            <span
              v-for="(item, index) in operateList"
              :key="index"
              :style="{ color: item.color }"
              class="hand"
              @click="editHandle(scope.row, item.callout)"
              >{{ item.name }}</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    Arr: Array, // 表格数据
    Title: Array, // 头部数据
    operateList: Array,
    tltleName: String,
    caozuo: Boolean, // 操作
  },
  data() {
    return {
      rightHeader: [{ label: "", value: "" }],
      rightsDate: [],
    };
  },
  // 监听父组件数据的变化
  watch: {
    Arr: {
      handler() {
        this.processingData();
      },
      immediate: true,
      deep: true,
    },
    //
  },
  created() {},
  methods: {
    processingData() {
      this.rightHeader = this.Title;
      this.rightsDate = this.Arr;
    },
    // 点击行事件
    row_click(e) {
      this.$emit("row-click", e);
    },
    editHandle(row, type) {
      this.$emit("AllType", row, type);
    },
  },
};
</script>

<style scoped lang="less">
::v-deep tbody {
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(105, 255, 250, 0.26) 0%,
    rgba(56, 255, 242, 0) 100%
  );
}

::v-deep .el-table tr {
  height: 80px;
  color: #fff;
}

::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
// 表格的间隔
::v-deep .el-table__body {
  -webkit-border-vertical-spacing: 13px; // 垂直间距
}

.el-table {
  --el-table-border-color: var(--el-border-color-lighter);
  --el-table-border-color: rgb(0 0 0 / 6%);
  --el-table-text-color: var(--el-text-color-regular);
  --el-table-header-text-color: var(--el-text-color-secondary);
  --el-table-row-hover-bg-color: var(--el-fill-color-light);
  --el-table-current-row-bg-color: var(--el-color-primary-light-9);
  --el-table-header-bg-color: var(--el-bg-color);
  --el-table-fixed-box-shadow: var(--el-box-shadow-light);
  --el-table-bg-color: var(--el-fill-color-blank);
  --el-table-tr-bg-color: var(--el-fill-color-blank);
  --el-table-expanded-cell-bg-color: var(--el-fill-color-blank);
  --el-table-fixed-left-column: inset 0.526316rem 0 0.526316rem -0.526316rem rgba(0, 0, 0, 0.15);
  --el-table-fixed-right-column: inset -0.526316rem 0 0.526316rem -0.526316rem
    rgba(0, 0, 0, 0.15);
}
</style>
